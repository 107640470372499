import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Typography, Button, Space, notification } from 'antd';
import axios from 'axios';
import AuthCheck from '../User/AuthCheck';
import ReactQuill from 'react-quill';

const { Title } = Typography;

const AddNews = () => {
  const [api, contextHolder] = notification.useNotification();
  let history = useHistory();

  const onSubmit = (values) => {
    axios
      .post(
        'api/news/add',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('News added successfully!');
          history.push('/news');
        } else {
          api.error({
            message: `Add news error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Add news error`,
          description: 'Invalid data!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      <Layout style={{ padding: '24px 50px' }}>
        { contextHolder }
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Add News</Title>
          <p>
            Contribute to the financial conversation! Add a news article to the simulator, 
            sharing relevant updates and insights with fellow participants. Shape the market 
            narrative and influence trading decisions with your informative posts.
          </p>
        </div>
        <Form
          layout="vertical"
          name="addNews"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please input title!',
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input description!',
                  }
                ]}
              >
                <ReactQuill theme="snow" style={{height: '200px', marginBottom: '42px'}}/>
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={()=> history.push('/news')}>
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Layout>
    </AuthCheck>
  );
}

export default AddNews;