import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Form, Input, Row, Col, Typography, InputNumber, Button, Space, notification } from 'antd';
import axios from 'axios';
import { fetchStocks } from '../../actions';
import AuthCheck from '../User/AuthCheck';

const { Title } = Typography;
const { TextArea } = Input;

const EditStock = () => {
  const [api, contextHolder] = notification.useNotification();
  const stocks = useSelector((state) => state.stocks);
  const dispatch = useDispatch();

  let history = useHistory();
  let params= useParams();

  const [form] = Form.useForm();
  const [stock, setStock] = useState(undefined);

  useEffect(()=> {
    dispatch(fetchStocks());
  }, [dispatch])

  useEffect(() => {
    if ( stocks && stocks.length > 0 ){
      const s = stocks.find(e => e._id === params.id);
      if (!s) return;
      form.setFieldsValue({
        name: s.name,
        quantity: s.quantity,
        rate: s.rate,
        marketRate: s.marketRate,
        description: s.description,
        maxTradePercentage: s.maxTradePercentage,
        maxCircuitPercentage: s.maxCircuitPercentage
      })
      setStock(s)
    }
  }, [params, stocks, setStock, form]);

  const onSubmit = (values) => {
    axios
      .patch(
        `api/stocks/edit/${params.id}`,
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('Stock edited successfully!');
          history.push('/stocks');
        } else {
          api.error({
            message: `Edit stock error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Edit stock error`,
          description: 'Invalid data!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      { stock ?
        <Layout style={{ padding: '24px 50px' }}>
          { contextHolder }
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>Edit Stock</Title>
            <p>
              Expand our stock market universe! Add a new stock to the simulation by providing its 
              name, description, and initial quantity. Create exciting investment opportunities for 
              participants and watch the market thrive with your contribution.
            </p>
          </div>
          <Form
            form={form}
            layout="vertical"
            name="editstock"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input stock name!',
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: `Invalid quantity. It should more than ${stock.quantity}!`,
                      validator: (_, v) => {
                        return v > stock.quantity || v === stock.quantity ? 
                          Promise.resolve() : 
                          Promise.reject();
                      }
                    },
                  ]}
                >
                  <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Rate"
                  name="rate"
                  rules={[
                    {
                      required: true,
                      message: 'Invalid rate. It should more than 0!',
                      validator: (_, v) => {
                        return v > 0 ? Promise.resolve() : Promise.reject('Invalid rate. It should more than 0!');
                      }
                    },
                  ]}
                >
                  <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Market Rate"
                  name="marketRate"
                  rules={[
                    {
                      required: true,
                      message: 'Invalid rate. It should more than 0!',
                      validator: (_, v) => {
                        return v > 0 ? Promise.resolve() : Promise.reject('Invalid rate. It should more than 0!');
                      }
                    },
                  ]}
                >
                  <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item
                  label="Max Trade Percentage"
                  name="maxTradePercentage"
                >
                  <InputNumber min={0} max={100} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Max Circuit Percentage"
                  name="maxCircuitPercentage"
                >
                  <InputNumber min={0} max={100} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Description"
                  name="description"
                >
                    <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Space>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={()=> history.push('/stocks')}>
                  Cancel
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Layout> : <></>
      }
    </AuthCheck>
  );
}

export default EditStock;