import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Chart = ({transactions}) => {

  const options = {
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
    },
    yAxis: {
      title: {
        text: null
      },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: 'Rate: {point.y}',
      shared: true
    },
    series: [{
      type: 'area',
      showInLegend: false, 
      data: transactions.map(e => e.rate)
    }]
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

export default Chart;