import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Typography, Space, Input, Button } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { fetchApplications, fetchIPOs, fetchUser } from '../../actions';
import AuthCheck from '../User/AuthCheck';
import ApplyForm from './ApplyForm';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const IPOs = () => {
  const ipos = useSelector((state) => state.ipos);
  const ipoApplications = useSelector((state) => state.ipoApplications);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let history = useHistory();

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findIPOs = ipos.filter((e) => 
        e.name.toLowerCase().indexOf(val) >= 0 ||
        e.description.toLowerCase().indexOf(val) >= 0
      )
      setData(findIPOs);
    } else {
      setData(ipos);
    }
  };

  const reloadData = () => {
    dispatch(fetchUser());
    dispatch(fetchIPOs());
    dispatch(fetchApplications());
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<b>{record.name}</b>)
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '300px'
    },
    {
      title: 'Detail',
      key: 'detail',
      render: (_, record) => (
        <>
          <div><b>Price per share:</b> {record.rate}</div>
          <div><b>Lot Size:</b> {record.lotSize}</div>
          <div><b>Price for one lot:</b> {record.lotSize * record.rate}</div>
          <div><b>Lots Available:</b> {record.quantity}</div>
          <div><b>Total shares issued in market:</b> {record.lotSize * record.quantity}</div>
        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          { 
            auth?.isAdmin ? 
              <>
                <Link to={`/ipos/edit/${record._id}`}><EditOutlined /></Link>
                <Link to={`/ipos/view/${record._id}`}><InfoCircleOutlined /></Link>
              </>
              : 
              <>
                {
                  record.distributed ?
                    <span style={{color: '#f5222d'}}>IPO Closed</span> :
                    <ApplyForm record={record} reloadData={reloadData}/>
                }
              </>
          }
        </Space>
      )
    }
  ]

  useEffect(()=> {
    dispatch(fetchIPOs());
    dispatch(fetchApplications());
  }, [dispatch])

  useEffect( ()=> {
    if(!ipos || !ipoApplications) return;
    async function updateData() {
      await ipos.forEach( async(e) => {
        const alreadyApplied = await ipoApplications.some( s => s.ipoId === e._id);
        if(alreadyApplied) {
          e.isApplied = true;
        }
      });
      setData(ipos);
    }
    updateData();
  }, [ipos, ipoApplications])

  return (
    <AuthCheck>
      { 
        auth && data ?
        <Content className="site-layout" style={{ padding: '24px 50px' }}>
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>IPOs <small style={{ fontSize: '14px', lineHeight: 1, color: '#f5222d' }}>({`You current balance is ${(auth.points * 0.15) - auth.ipo} points`})</small></Title>
            <p>
              Explore the latest IPO opportunities in our simulator. The IPO list showcases 
              upcoming initial public offerings, complete with details on name, quantity 
              available, and IPO value in points per lot. Discover exciting investment 
              prospects and secure your position in promising companies.
            </p>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Space size="middle">
                <Search placeholder="search ipo" onSearch={onSearch} style={{ width: 200 }} allowClear/>
                { auth?.isAdmin ? <Button type='primary' onClick={() => history.push('/ipos/add')}>Add IPO</Button> : <></> }
              </Space>
            </div>
          </div>
          {
            data && data.length > 0 ? <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : <p>No data found!</p>
          }
        </Content> : <></>
      }
    </AuthCheck>
  );
}

export default IPOs;