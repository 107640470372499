import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Typography, Space, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { fetchNews } from '../../actions';
import AuthCheck from '../User/AuthCheck';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const News = () => {
  const news = useSelector((state) => state.news);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let history = useHistory();

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findNews = news.filter((e) => 
        e.title.toLowerCase().indexOf(val) >= 0 ||
        e.description.toLowerCase().indexOf(val) >= 0
      )
      setData(findNews);
    } else {
      setData(news);
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      key: 'description',
      render: (_, record) => (
        <div dangerouslySetInnerHTML={{ __html: record.description }} />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          { auth?.isAdmin ? <Link to={`/news/edit/${record._id}`}><EditOutlined /></Link> : <></> }
        </Space>
      )
    }
  ]

  useEffect(()=> {
    dispatch(fetchNews());
  }, [dispatch])

  useEffect( ()=> {
    if(!news) return;
    setData(news);
  }, [news])

  return (
    <AuthCheck>
      { 
        auth && data ?
        <Content className="site-layout" style={{ padding: '24px 50px' }}>
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>News</Title>
            <p>
              Stay informed and make timely decisions. Explore our curated news list, providing you with the 
              latest updates and events that impact the stock market. Keep your finger on the pulse of financial 
              news and leverage insights to enhance your trading strategies.
            </p>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Space size="middle">
                <Search placeholder="search news" onSearch={onSearch} style={{ width: 200 }} allowClear/>
                { auth?.isAdmin ? <Button type='primary' onClick={() => history.push('/news/add')}>Add News</Button> : <></> }
              </Space>
            </div>
          </div>
          {
            data && data.length > 0 ? <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : <p>No data found!</p>
          }
        </Content> : <></>
      }
    </AuthCheck>
  );
}

export default News;