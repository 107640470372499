import axios from 'axios';
import { 
  FETCH_USER, 
  LOGOUT_USER, 
  LOGIN_USER, 
  FETCH_PARTICIPANTS, 
  FETCH_STOCKS, 
  FETCH_MARKET, 
  FETCH_PORTFOLIO, 
  FETCH_IPOS, 
  FETCH_IPOAPPLICATIONS,
  FETCH_NEWS,
  SELL_UPDATED
} from './types';

export const fetchUser = () => async (dispatch) => {
  const res = await axios.get('/auth/user');
  console.log('fetch user', res);
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const loginUser = () => async (dispatch) => {
  const res = await axios.post('/auth/login');
  dispatch({ type: LOGIN_USER, payload: res.data });
};

export const logoutUser = () => async (dispatch) => {
  const res = await axios.get('/auth/logout');
  console.log('logout dispatched', res.data);
  dispatch({ type: LOGOUT_USER, payload: res.data });
};

export const fetchParticipants = () => async (dispatch) => {
  const res = await axios.get('/api/participants/list');
  dispatch({ type: FETCH_PARTICIPANTS, payload: res.data });
};

export const fetchStocks = () => async (dispatch) => {
  const res = await axios.get('/api/stocks/list');
  dispatch({ type: FETCH_STOCKS, payload: res.data });
};

export const fetchMarket = (id) => async (dispatch) => {
  const res = await axios.get(`/api/market/list/${id}`);
  dispatch({ type: FETCH_MARKET, payload: res.data });
};

export const fetchPortfolio = () => async (dispatch) => {
  const res = await axios.get('/api/portfolio');
  dispatch({ type: FETCH_PORTFOLIO, payload: res.data });
};

export const fetchIPOs = () => async (dispatch) => {
  const res = await axios.get('/api/ipos/list');
  dispatch({ type: FETCH_IPOS, payload: res.data });
};

export const fetchApplications = () => async (dispatch) => {
  const res = await axios.get('/api/ipos/applied');
  dispatch({ type: FETCH_IPOAPPLICATIONS, payload: res.data });
};

export const fetchNews = () => async (dispatch) => {
  const res = await axios.get('/api/news/list');
  dispatch({ type: FETCH_NEWS, payload: res.data });
};

export const sellUpdate = () => async (dispatch) => {
  dispatch({ type: SELL_UPDATED });
};