export const FETCH_USER = 'fetch_user';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';

export const FETCH_PARTICIPANTS = 'fetch_participants';

export const FETCH_STOCKS = 'fetch_stocks';
export const FETCH_MARKET = 'fetch_market';
export const FETCH_PORTFOLIO = 'fetch_protfolio';
export const FETCH_IPOS = 'fetch_ipos';
export const FETCH_IPOAPPLICATIONS = 'fetch_applications';
export const FETCH_NEWS = 'fetch_news';

export const SELL_UPDATED = 'sell_updated';