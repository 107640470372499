import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchMarket, fetchUser } from '../../actions';
import { useParams } from 'react-router-dom';
import { Space, InputNumber, Button, Form, notification } from 'antd';


const BuyForm = ({record}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let params= useParams();

  const onBuy = (values) => {
    values.stockId = record.stockId;
    values.stockName = record.stockName;
    values.rate = record.rate;
    values.sellId = record._id;
    values.marketId = record.marketId;

    axios
      .post(
        'api/market/buy',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        dispatch(fetchMarket(params.id));
        dispatch(fetchUser());
        form.resetFields();
      }).catch((error) => {
          api.error({
            message: `Buy stock error`,
            description: error.response?.data ? error.response.data : 'Something went worng. Contact administrator.',
            placement: 'topRight'
          });
      });
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="Buy"
      onFinish={onBuy}
    >
      { contextHolder }
      <Space align='start'>
        <Form.Item
          name="quantity"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: 'Invalid quantity.',
              validator: (_, v) => {
                if ( v === null || v === undefined || v > (record.quantity -  record.soldQty) )
                  return Promise.reject('Invalid quantity.');
                return Promise.resolve();
              }
            },
          ]}
        >
          <InputNumber min={1} controls={false} precision={0} placeholder='Quantity'/>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Buy
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
}

export default BuyForm;