import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Menu, Layout, Button, Space } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import { adminMenu, participantMenu } from '../../Helper';
import { logoutUser } from '../../actions';

const { Header } = Layout;

const HeaderBar = (props) => {
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [menu, setMenu] = useState([]);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let history = useHistory();

  const logout = () => {
    dispatch(logoutUser());
    history.push('/login');
    renderContent();
  }

  useEffect(()=> {
    const isAdminUser = auth && auth.isAdmin;
    setMenu(isAdminUser ? adminMenu : participantMenu);
  }, [auth]);

  useEffect(()=> {
    let isMounted = true;

    const findSelectedMenu = () => {
      if (isMounted) {
        if (history.location.pathname === '/')
          return setSelectedMenu([`menu-item-0`]);
        menu.forEach((e,i) => {
          if (history.location.pathname.indexOf(e.title.toLowerCase()) >= 0) {
            setSelectedMenu([`menu-item-${i}`]);
          }
        });
      }
    }

    findSelectedMenu();
    history.listen(() => findSelectedMenu());

    return () => {
      isMounted = false;
    };
  }, [menu, history]);

  const menuItem = (menu, i) => {
    return (
      <Menu.Item key={`menu-item-${i}`} data-key={`menu-item-${i}`}>
        <span>
          {menu.title}
          <Link to={menu.url} />
        </span>
      </Menu.Item>
    );
  }

  const renderContent = () => {
    switch (auth) {
      case null:
        return <></>;
      case '':
        return <Redirect to="/login" />;
      default:
        return (
          <Header style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 0 50px'
          }}>
            <Space>
              <a className='logo' href='/'><img src="exotica.png" alt="Agra Exotica" style={{height: '60px'}}/></a>
              <Menu theme="light" mode="horizontal" selectedKeys={selectedMenu}>
                { 
                  menu.length > 0 ? 
                    menu.map((menu,i) => menuItem(menu,i)) : <></>
                }
              </Menu>
            </Space>
            <Space>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span style={{ lineHeight: 1, fontSize: '24px' }}>{ auth.points - ((auth.out + auth.ipo) - auth.in)}</span>
                <small style={{ lineHeight: 1, color: '#adadad' }}>Wallet Balance</small>
              </div>
              <Button
                type="text"
                icon={<PoweroffOutlined />}
                onClick={logout}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                type="text"
                icon={props.collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                onClick={() => props.setCollapsed(!props.collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            </Space>
          </Header>
        );
    }
  };

  return renderContent();
}

export default HeaderBar;
