import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Typography, Table, Space, Input, Button } from 'antd';
import { EditOutlined, SearchOutlined, VerticalAlignMiddleOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AuthCheck from '../User/AuthCheck';
import { fetchStocks } from '../../actions';
import { Link, useHistory } from 'react-router-dom';
import Chart from './Chart';
import axios from 'axios';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const Dashboard = () => {
  const stocks = useSelector((state) => state.stocks);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let history = useHistory();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<b>{record.name}</b>)
    },
    {
      title: 'Intial Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'On Sell',
      dataIndex: 'onSell',
      key: 'onSell'
    },
    {
      title: 'Rate',
      key: 'rate',
      render: (_, record) => (
        <Space size="middle">
          <span style={{textAlign: 'right', display: 'inline-block', lineHeight: 1}}>
            {record.marketRate}<br/>
            <small style={{color: '#adadad'}}>{record.rate}</small>
          </span>
          { record.marketRate === record.rate ? <VerticalAlignMiddleOutlined style={{color: '#fa8c16'}}/> : <></> }
          { record.marketRate > record.rate ? <ArrowUpOutlined style={{color: '#389e0d'}}/> : <></> }
          { record.marketRate < record.rate ? <ArrowDownOutlined style={{color: '#f5222d'}}/> : <></> }
        </Space>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          { auth?.isAdmin ? <Link to={`/stocks/edit/${record._id}`}><EditOutlined /></Link> : <></> }
          <Link to={`/stocks/view/${record._id}`}><SearchOutlined /></Link>
          { auth?.isAdmin ? <Link to={`/stocks/participants/${record._id}`}><ExclamationCircleOutlined /></Link> : <></> }
        </Space>
      )
    }
  ]

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findStocks = stocks.filter((e) => 
        e.name.toLowerCase().indexOf(val) >= 0 ||
        e.description.toLowerCase().indexOf(val) >= 0
      )
      setData(findStocks);
    } else {
      setData(stocks);
    }
  };

  useEffect(()=> {
    if(stocks === null || stocks?.length === 0) return;

    let isMounted = true;
    const facthMarketList = () => {
      axios.get('/api/market/list',{ withCredentials: true }).then((res) => {
        if (isMounted) {
          const market = res.data;
          const _stocks = JSON.parse(JSON.stringify(stocks));
          if(market && _stocks) {
            _stocks.forEach(s => {
              let onSell = 0;
              market.forEach( m => {
                if (m.stockId === s._id) {
                  onSell += (m.totalOnSellQty - m.totalSoldQty);
                }
              });
              s.onSell = onSell;
            });
            setData(_stocks);
          }
        }
      });
    }

    facthMarketList();

    return () => {
      isMounted = false;
    };
  }, [stocks])

  useEffect(()=> {
    dispatch(fetchStocks());
  }, [dispatch])

  return (
    <AuthCheck>
      { 
        stocks ?
          <Content className="site-layout" style={{ padding: '24px 50px' }}>
            <div style={{ paddingBottom: '12px' }}>
              <Title level={3}>Stock Price Comparision</Title>
              { auth ?
                <>
                  <p>Welcome { auth.firstname } { auth.lastname } ({ auth.email })!</p>
                  <p>
                    Stay informed with real-time market data. Access the current market report to track stock prices,
                    trends, and vital statistics. Make data-driven trading decisions and navigate the stock market 
                    confidently with up-to-the-minute insights.
                  </p>
                </>
                : <></>
              }
            </div>
            <Chart stocks={stocks}/>
            { 
              data && data.length > 0 ?
                <>
                <div style={{ display: 'flex', justifyContent: 'end', margin: '42px 0 18px 0' }}>
                  <Space size="middle">
                    <Search placeholder="search stock" onSearch={onSearch} style={{ width: 200 }} allowClear/>
                    { auth?.isAdmin ? <Button type='primary' onClick={() => history.push('/stocks/add')}>Add Stock</Button> : <></> }
                  </Space>
                </div>
                <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/>
              </> : <></>
            }
          </Content> : <></>
      }
    </AuthCheck>
  );
}

export default Dashboard;