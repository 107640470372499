import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Card, Typography, Space } from "antd";
import { fetchNews } from '../../actions';
const { Sider } = Layout;

const { Title } = Typography;

const NewsView = (props) => {
  const auth = useSelector((state) => state.auth);
  const news = useSelector((state) => state.news);

  const dispatch = useDispatch();

  useEffect(()=> {
    if(!auth) return;
    dispatch(fetchNews());
  }, [dispatch, auth])

	const renderContent = () => {
    switch (auth) {
      case null:
        return <>Loading...</>;
      case '':
        return <></>;
      default:
        return (
          <Sider
						width={350}
						className="light-bg"
						trigger={null}
						collapsible
						collapsed={props.collapsed}
						collapsedWidth={0}
            style={{ overflow: 'auto', height: '100vh' }}
					>
            <Space direction="vertical" size={16} style={{ padding: '16px', width: '100%' }}>
              { 
                news ?
                  news.map( n => 
                    <Card key={n._id}>
                      <Title level={3}>{n.title}</Title>
                      <div dangerouslySetInnerHTML={{ __html: n.description }} />
                    </Card>
                  ) 
                : <>No News.</> 
              }
            </Space>
          </Sider>
        );
    }
  };

  return renderContent();
};

export default NewsView;
