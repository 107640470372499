import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Typography, Space, Input, Button } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import AuthCheck from '../User/AuthCheck';
import { fetchStocks, fetchParticipants } from '../../actions';
import axios from 'axios';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const Participants = () => {
  const stocks = useSelector((state) => state.stocks);
  const participants = useSelector((state) => state.participants);
  const dispatch = useDispatch();

  let history = useHistory();

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findParticipants = participants.filter((e) => 
        e.firstname.toLowerCase().indexOf(val) >= 0 ||
        e.lastname.toLowerCase().indexOf(val) >= 0 ||
        e.email.toLowerCase().indexOf(val) >= 0
      )
      setData(findParticipants);
    } else {
      setData(participants);
    }
  };

  const TotalBalance = ({record}) => {
    const total = (record.points - ((record.out + record.ipo) - record.in)) + (record.stockBalance + record.ipo);
    
    return (
      record.points < total ? 
      <div>
        {total}
        <div style={{ color: '#237804', fontSize: '11px', lineHeight: 1 }}> 
          {`Profit of ${total - record.points} Points (${parseFloat(((total - record.points) / record.points) * 100).toFixed(2)} %)`}
        </div> 
      </div> :
      <div>
        {total}
        <div style={{ color: '#f5222d', fontSize: '11px', lineHeight: 1 }}>
          {`Loss of ${record.points - total } Points (${parseFloat(((total - record.points) / record.points) * 100).toFixed(2)} %)`}
        </div>
      </div>
    )
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => (<b>{record.firstname} {record.lastname}</b>),
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Initial',
      dataIndex: 'points',
      key: 'points'
    },
    {
      title: 'Wallet',
      key: 'balance',
      render: (_, record) => (
        record.points - ((record.out + record.ipo) - record.in)
      ),
      sorter: (a, b) => 
        (a.points - ((a.out + a.ipo) - a.in)) - 
        (b.points - ((b.out + b.ipo) - b.in)),
    },
    {
      title: 'Total',
      key: 'total',
      render: (_, record) => <TotalBalance record={record}/>,
      sorter: (a, b) => 
        ((a.points - ((a.out + a.ipo) - a.in)) + (a.stockBalance + a.ipo)) - 
        ((b.points - ((b.out + b.ipo) - b.in)) + (b.stockBalance + b.ipo)),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/participants/edit/${record._id}`}><EditOutlined /></Link>
          <Link to={`/participants/view/${record._id}`}><SearchOutlined /></Link>
        </Space>
      )
    }
  ]

  useEffect(()=> {
    dispatch(fetchStocks());
    dispatch(fetchParticipants());
  }, [dispatch])

  const average = arr => parseFloat(arr.reduce( ( p, c ) => p + c, 0 ) / arr.length).toFixed(0);

  useEffect(()=> {
    if(stocks === null) return;
    if(participants === null || participants?.length === 0) return;

    let isMounted = true;
    const facthMarketList = async () => {
      await axios.get('/api/market/list',{ withCredentials: true }).then( async(res) => {
        if (isMounted) {
          const market = res.data;
          const _participants = JSON.parse(JSON.stringify(participants));
          await _participants.forEach( async(p) => {
            p.stockBalance = 0;
            await market.forEach( async (e) => {
              if (p._id === e.userId) {
                const buys = await e.buy.map( s => s.rate );
                const stock = await stocks.find(s => s._id === e.stockId);
                e.avgBuyRate = average(buys);
                p.stockBalance += ((e.totalBuyQty - e.totalSoldQty) * stock.marketRate);
              }
            });
          });
          setData(_participants);
        }
      });
    }

    facthMarketList();

    return () => {
      isMounted = false;
    };
  }, [participants, stocks])

  return (
    <AuthCheck>
      <Content className="site-layout" style={{ padding: '24px 50px' }}>
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Participants</Title>
          <p>
            Explore our vibrant community of participants in the Stock Market Simulator. 
            View the list of traders, investors, and learners like yourself who are actively 
            engaging with the market. Connect, compete, and learn from one another as you 
            navigate the world of stock trading together.
          </p>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Space size="middle">
              <Search placeholder="search participant" onSearch={onSearch} style={{ width: 200 }} allowClear/>
              <Button type='primary' onClick={() => history.push('/participants/add')}>Add Participant</Button>
            </Space>
          </div>
        </div>
        {
          data && data.length > 0 ? <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : <p>No data found!</p>
        }
      </Content>
    </AuthCheck>
  );
}

export default Participants;