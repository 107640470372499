import React from "react";
import { useHistory } from 'react-router-dom';
import { Layout, Typography, Button } from 'antd';

const { Title } = Typography;

const PageNotFound = () => {
  let history = useHistory();

  return (
    <Layout style={{ minHeight: '100vh', justifyContent: 'center', maxWidth: '500px', padding: '0 50px', alignSelf: 'center', textAlign: 'center' }}>
      <Title level={1} type="danger">404</Title>
      <p>The page you are looking for might have been removed, had it's name changed or is temporarily unavailable.</p>
      <Button onClick={()=> history.push('/')}>
        Go To Dashboard
      </Button>
    </Layout>
  );
};

export default PageNotFound;
