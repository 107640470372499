import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Typography, InputNumber, Button, Space, notification } from 'antd';
import axios from 'axios';
import AuthCheck from '../User/AuthCheck';

const { Title } = Typography;

const AddParticipant = () => {
  const [api, contextHolder] = notification.useNotification();
  let history = useHistory();

  const onSubmit = (values) => {
    axios
      .post(
        'api/participants/add',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('Participant added successfully!');
          history.push('/participants');
        } else {
          api.error({
            message: `Add participant error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Add participant error`,
          description: 'Invalid data!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      <Layout style={{ padding: '24px 50px' }}>
        { contextHolder }
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Add Participant</Title>
          <p>
            Fill out the form below to create your participant account and start 
            learning about stock trading in a risk-free environment. Get ready 
            to explore, invest, and compete with fellow participants. Let's get 
            started!
          </p>
        </div>
          <Form
            layout="vertical"
            name="addParticipant"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                    {
                      message: 'Invalid email address!',
                      validator: (_, v) => {
                        // eslint-disable-next-line no-useless-escape
                        const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        return v.match(validRegex) ? Promise.resolve() : Promise.reject('Invalid email address!');
                      }
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password.' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Initial Points"
                  name="points"
                  rules={[
                    {
                      message: 'Invalid points. It should more than 0!',
                      validator: (_, v) => {
                        return v > 0 ? Promise.resolve() : Promise.reject('Invalid points. It should more than 0!');
                      }
                    },
                  ]}
                >
                  <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Middle Name"
                  name="middlename"
                  rules={[{ required: true, message: 'Please input your middle name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Address" name="address" >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="City" name="city" >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="State" name="state" >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Postalcode" name="postalcode" >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Phone" name="phone" 
                  rules={[
                    {
                      message: 'Invalid phone number. It should be 10 digit!',
                      validator: (_, v) => {
                        if ( v === null || v === undefined ) return Promise.resolve();
                        if ( v.toString().length > 0 && v.toString().length === 10 ) return Promise.resolve();
                        return Promise.reject('Invalid phone number. It should be 10 digit!');
                      }
                    },
                  ]}>
                  <InputNumber controls={false} precision={0} style={{ width: '100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Space>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={()=> history.push('/participants')}>
                  Cancel
                </Button>
              </Form.Item>
            </Space>
          </Form>
      </Layout>
    </AuthCheck>
  );
}

export default AddParticipant;