import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AuthCheck = (props) => {
  const auth = useSelector((state) => state.auth);

  const renderContent = () => {
    switch (auth) {
      case null:
        return <>Loading...</>;
      case '':
        return <Redirect to="/login" />;
      default:
        return (
          <>
            {props.children}
          </>
        );
    }
  };

  return renderContent();
}

export default AuthCheck;