import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Layout } from 'antd';
import { io } from 'socket.io-client';
import { appURL } from '../Helper';
import '../App.css';
import 'react-quill/dist/quill.snow.css';

import HeaderBar from './Layout/HeaderBar';
import NewsView from './Layout/NewsView';
import PageNotFound from './Layout/PageNotFound';
import Login from './User/Login';
import Signup from './User/Signup';
import Dashboard from './Dashboard/Index';
import AddParticipant from './Participants/Add';
import EditParticipant from './Participants/Edit';
import Participants from './Participants/Index';
import AddStock from './Stocks/Add';
import Stocks from './Stocks/Index';
import EditStock from './Stocks/Edit';
import StocksByParticipant from './Stocks/StocksByParticipant';
import ViewMarket from './Stocks/View';
import Portfolio from './Portfolio/Index';
import IPOs from './IPOs/Index';
import AddIPO from './IPOs/Add';
import EditIPO from './IPOs/Edit';
import News from './News/Index';
import AddNews from './News/Add';
import EditNews from './News/Edit';
import ViewIPOs from './IPOs/View';
import ParticipantPortfolio from './Portfolio/ParticipantPortfolio';
import Settings from './Settings/Settings';


const socket = io(`wss://${appURL}`);
const { Footer } = Layout;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { collapsed: false };
    socket.on('news-updates', () => {
      console.log('io triggered news update!');
      this.props.fetchNews();
    });
    socket.on('stock-updates', () => {
      console.log('io triggered stock update!');
      this.props.fetchPortfolio();
      this.props.fetchStocks();
    });
    socket.on('market-updates', () => {
      console.log('io triggered market update!');
      this.props.fetchStocks();
      this.props.sellUpdate();
      this.props.fetchUser();
      this.props.fetchPortfolio();
    });
    socket.on('ipo-updates', () => {
      console.log('io triggered ipo update!');
      this.props.fetchIPOs();
    });
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  setCollapsed = (val) => {
    this.setState({ collapsed: val });
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout style={{ height: '100vh', overflow: 'auto', display: 'block' }}>
          <HashRouter>
            <HeaderBar collapsed={this.state.collapsed} setCollapsed={this.setCollapsed}/>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/stocks" component={Stocks}/>
              <Route exact path="/stocks/add" component={AddStock}/>
              <Route exact path="/stocks/view/:id" component={ViewMarket}/>
              <Route exact path="/stocks/edit/:id" component={EditStock}/>
              <Route exact path="/stocks/participants/:id" component={StocksByParticipant}/>
              <Route exact path="/ipos" component={IPOs}/>
              <Route exact path="/ipos/add" component={AddIPO}/>
              <Route exact path="/ipos/edit/:id" component={EditIPO}/>
              <Route exact path="/ipos/view/:id" component={ViewIPOs}/>
              <Route exact path="/news" component={News}/>
              <Route exact path="/news/add" component={AddNews}/>
              <Route exact path="/news/edit/:id" component={EditNews}/>
              <Route exact path="/participants/add" component={AddParticipant}/>
              <Route exact path="/participants" component={Participants}/>
              <Route exact path="/participants/edit/:id" component={EditParticipant}/>
              <Route exact path="/participants/view/:id" component={ParticipantPortfolio}/>
              <Route exact path="/portfolio" component={Portfolio}/>
              <Route exact path="/settings" component={Settings}/>
              <Route exact path="/login" component={Login} />
              <Route component={PageNotFound}/>
            </Switch>
            <Footer style={{ textAlign: 'center', background: 'transparent' }}>QShareMarket ©2023 Created by Toshal Infotech</Footer>
          </HashRouter>
        </Layout>
        <NewsView collapsed={this.state.collapsed}/>
      </Layout>
    );
  }
}

function mapStateToProps({ auth, news, stocks, events, ipos }) {
  return {
    auth,
    news,
    stocks,
    events,
    ipos
  };
}

export default connect(mapStateToProps, actions)(App);
