import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Typography, Table, Space, Input, Card, Row, Col } from 'antd';
import { SearchOutlined, VerticalAlignMiddleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import AuthCheck from '../User/AuthCheck';
import { fetchPortfolio } from '../../actions';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const Profile = () => {
  const {portfolio , ipos } = useSelector((state) => 
    state.portfolio ? state.portfolio : { portfolio: undefined, ipos: undefined} );
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const MarketValue = ({record}) => {
    const mValue = (record.totalBuyQty - record.totalSoldQty) * record.marketRate;
    const iValue = (record.totalBuyQty - record.totalSoldQty) * record.avgBuyRate;

    let textColor = '#000';
    if ( mValue > iValue ) textColor = '#389e0d';
    if ( mValue < iValue ) textColor = '#f5222d';

    return (
      <>
        <Space>
          {mValue}
          { mValue === iValue ? <VerticalAlignMiddleOutlined style={{color: '#fa8c16'}}/> : <></> }
          { mValue > iValue ? <ArrowUpOutlined style={{color: '#389e0d'}}/> : <></> }
          { mValue < iValue ? <ArrowDownOutlined style={{color: '#f5222d'}}/> : <></> }
        </Space>
        <div>
          <small style={{lineHeight: 1, color: textColor }}>
            { mValue > iValue ? '+' : '' }{ mValue - iValue } Points
          </small>
        </div>
      </>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'stockName',
      key: 'stockName',
      render: (_, record) => (<b>{record.stockName}</b>)
    },
    {
      title: 'Buy',
      dataIndex: 'totalBuyQty',
      key: 'totalBuyQty'
    },
    {
      title: 'On Sell',
      dataIndex: 'totalOnSellQty',
      key: 'totalOnSellQty'
    },
    {
      title: 'Sold',
      dataIndex: 'totalSoldQty',
      key: 'totalSoldQty'
    },
    {
      title: (<>Qty<br/>Available</>),
      key: 'balance',
      render: (_, record) => (
        record.totalBuyQty - record.totalSoldQty
      )
    },
    {
      title: (<>Avg Buy<br/>Rate</>),
      key: 'avgRate',
      render: (_, record) => (
        record.avgBuyRate
      )
    },
    {
      title: (<>Points<br/>Invested</>),
      key: 'invested',
      render: (_, record) => (
        (record.totalBuyQty - record.totalSoldQty) * record.avgBuyRate
      )
    },
    {
      title: (<>Current<br/>Market Point</>),
      dataIndex: 'marketRate',
      key: 'marketRate'
    },
    {
      title: (<>Current<br/>Market Value</>),
      key: 'points',
      render: (_, record) => (
        <MarketValue record={record}/>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/stocks/view/${record.stockId}`}><SearchOutlined /></Link>
        </Space>
      )
    }
  ]

  const ipoColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<b>{record.name}</b>)
    },
    {
      title: 'Lot Asked',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Lot Size',
      dataIndex: 'lotSize',
      key: 'lotSize',
    },
    {
      title: 'Total Shares Ask',
      key: 'sharesAsk',
      render: (_, record) => (
        record.quantity * record.lotSize
      )
    },
    {
      title: 'Shared Allocated',
      dataIndex: 'sharesAlloted',
      key: 'sharesAlloted',
    },
    {
      title: 'Value',
      key: 'value',
      render: (_, record) => (
        record.sharesAlloted === 0 ? record.quantity * record.lotSize * record.rate : <span style={{color: '#f5222d'}}>N/A</span>
      )
    }
  ]

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findStocks = portfolio.filter((e) => e.stockName.toLowerCase().indexOf(val) >= 0)
      setData(findStocks);
    } else {
      setData(portfolio);
    }
  };

  const [stockBalance, setStockBalance] = useState(0);

  const average = arr => parseFloat(arr.reduce( ( p, c ) => p + c, 0 ) / arr.length).toFixed(0);

  useEffect(()=> {
    if(!portfolio) return;
    let _stockBalance = 0;
    portfolio.forEach((e) => {
      const buys = e.buy.map( s => s.rate );
      e.avgBuyRate = average(buys);
      _stockBalance += ((e.totalBuyQty - e.totalSoldQty) * (e.marketRate ? e.marketRate : 0));
    });
    setStockBalance(_stockBalance);
    setData(portfolio);
  }, [portfolio])

  useEffect(()=> {
    dispatch(fetchPortfolio());
  }, [dispatch])

  const WalletTotal = () => {
    const total = (auth.points - ((auth.out + auth.ipo) - auth.in)) + (stockBalance + auth.ipo);
    
    return (
      auth.points < total ? 
      <b style={{ color: '#52c41a' }}> 
        {`Profit of ${total - auth.points} Points (${parseFloat(((total - auth.points) / auth.points) * 100).toFixed(2)} %)`}
      </b> :
      <b style={{ color: '#f5222d' }}>
        {`Loss of ${auth.points - total } Points (${parseFloat(((total - auth.points) / auth.points) * 100).toFixed(2)} %)`}
      </b>
    )

  }

  return (
    <AuthCheck>
      { auth && portfolio ?
        <Content className="site-layout" style={{ padding: '24px 50px' }}>
          <div style={{ paddingBottom: '12px' }}>
            <Title level={1}>Portfolio</Title>
            <p>Welcome { auth.firstname } { auth.lastname } ({ auth.email })!</p>
            <p>
              Review and manage your stock and IPO investments with ease. The participant portfolio 
              listing page offers a comprehensive view of your current holdings, allowing you to track 
              your investments, adjust your strategy, and make informed decisions for a successful 
              trading journey.
            </p>
            <Row gutter={16} style={{ margin: '46px -8px' }}>
              <Col span={6}>
                <Card type="inner" style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{ lineHeight: 1}}>&nbsp;</span>
                    <span style={{ lineHeight: 1, fontSize: '36px' }}>{ auth.points - ((auth.out + auth.ipo) - auth.in)}</span>
                    <span style={{ color: '#adadad' }}>Wallet Balance</span>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card type="inner" style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <span style={{ lineHeight: 1}}>&nbsp;</span>
                    <span style={{ lineHeight: 1, fontSize: '36px' }}>{ stockBalance }</span>
                    <span style={{ color: '#adadad' }}>Invested Points</span>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card type="inner" style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{ lineHeight: 1}}>&nbsp;</span>
                    <span style={{ lineHeight: 1, fontSize: '36px' }}>{ auth.ipo }</span>
                    <span style={{ color: '#adadad' }}>IPO Balance</span>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card type="inner" style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{ lineHeight: 1}}>Wallet + Invested + IPO + Profit/Loss</span>
                    <span style={{ lineHeight: 1, fontSize: '36px' }}>{ (auth.points - ((auth.out + auth.ipo) - auth.in)) + (stockBalance + auth.ipo)}</span>
                    <WalletTotal/>
                  </div>
                </Card>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title level={3} style={{ margin: 0 }}>Stocks</Title>
              <Space size="middle">
                <Search placeholder="search stock" onSearch={onSearch} style={{ width: 200 }} allowClear/>
              </Space>
            </div>
          </div>
          {
            data && data.length > 0 ? 
              <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : 
              <p>No stock data found!</p>
          }
          {
            ipos && ipos.length > 0 ?
            <>
              <Title level={3} style={{ marginTop: 24 }}>IPO's</Title>
              <Table columns={ipoColumns} dataSource={ipos} rowKey="_id" pagination={false}/>
            </> :
            <>
              <Title level={3} style={{ marginTop: 24 }}>IPO's</Title>
              <p>No IPO data found!</p>
            </>
          }
        </Content> : <></>
      }
    </AuthCheck>
  );
}

export default Profile;