export const appURL = window.location.hostname === 'localhost' ? 'localhost:5000' : 'qsharemarket.toshalinfotech.com';

export const adminMenu = [
    { title: 'Dashboard', url: '/' },
    { title: 'Participants', url: '/participants' },
    { title: 'Stocks', url: '/stocks' },
    { title: 'IPOs', url: '/ipos' },
    { title: 'News', url: '/news' },
    { title: 'Portfolio', url: '/portfolio'},
    { title: 'Settings', url: '/settings'}
]

export const participantMenu = [
    { title: 'Dashboard', url: '/' },
    { title: 'Stocks', url: '/stocks' },
    { title: 'IPOs', url: '/ipos' },
    { title: 'Portfolio', url: '/portfolio' }
]