import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchMarket } from '../../actions';
import { useParams } from 'react-router-dom';
import { Space, InputNumber, Button, Form, notification  } from 'antd';


const AddSellForm = ({ marketId, currentStock}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let params= useParams();

  const onSell = (values) => {
    values.marketId = marketId;
    axios
      .post(
        'api/market/sell',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        api.success({
          message: `Sell added`,
          description: response.data?.message ? response.data.message : 'Sell add in the market successfully!',
          placement: 'topRight'
        });
        dispatch(fetchMarket(params.id));
        form.resetFields();
      }).catch((error) => {
        api.error({
          message: `Sell errors`,
          description: error.response?.data ? error.response.data : 'Something went worng. Contact administrator.',
          placement: 'topRight'
        });
      });
  }

  const onCancel = () => {
    form.resetFields();
  }

  const onSubmitFailed = (errorInfo) => {
    api.error({
      message: `Sell errors`,
      description: 
      <ul style={{paddingLeft: '18px', color: '#f5222d'}}>
        { errorInfo.errorFields.map( (e,i) => <li key={`error-${i}`}>{e.errors[0]}</li>) }
      </ul>,
      placement: 'topRight'
    });
  }


  return (
    <Space>
      {contextHolder}
      <Form
        className='AddSellForm'
        form={form}
        layout="vertical"
        name="Sell"
        onFinish={onSell}
        onFinishFailed={onSubmitFailed}
      >
        <Space align='start'>
          <Form.Item
            name="quantity"
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                validator: (_, v) => {
                  if ( v === null || v === undefined ) return Promise.reject('Invalid quantity. It should more than 0!');
                  if ( v > currentStock  ) return Promise.reject(`Invalid quantity. It should less than ${currentStock + 1}!`);
                  return Promise.resolve();
                }
              },
            ]}
          >
            <InputNumber min={1} controls={false} precision={0} placeholder="Qauntity"/>
          </Form.Item>
          <Form.Item
            name="rate"
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: 'Invalid rate. Rate amount should greater than 0.',
              },
            ]}
          >
            <InputNumber min={1} controls={false} precision={0} placeholder="Rate"/>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Add New
              </Button>
              <Button onClick={onCancel}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
}

export default AddSellForm;