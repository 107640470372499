import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Layout, Form, Input, Button, Typography, Space } from "antd";
import axios from "axios";

const { Title } = Typography;

const Signup = () => {
  let history = useHistory();

  const onSubmit = (values) => {
    console.log(values);

    axios.patch("/api/participants/edit/65117a9947f49831b027f0ec",
        {
          password: values.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response);
        if (!response.data.errmsg) {
          console.log("successful signup");
          history.push("/login");
        } else {
          console.log("email already taken");
        }
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        justifyContent: "center",
        maxWidth: "500px",
        padding: "0 50px",
        alignSelf: "center",
      }}
    >
      <Space direction="vertical">
        <div style={{ textAlign: "center", paddingBottom: "4%" }}>
          <Title>Sign Up</Title>
          <p>
            Access all your past, current, and future projects on the client
            dashboard.
          </p>
        </div>
        <Form
          layout="vertical"
          style={{ flex: 2 }}
          name="signup"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password." }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
          <Link to="/login">
            <p>Already have an account? Log In instead.</p>
          </Link>
        </Form>
      </Space>
    </Layout>
  );
};

export default Signup;
