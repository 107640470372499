import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
    lang: {
      thousandsSep: ''
  },
});

const Chart = ({stocks}) => {

    const categories = stocks.map(e => e.name);
    const initRate = stocks.map(e => e.rate);
    const marketRate = stocks.map(e => e.marketRate);

    const options = {
      // chart: {
      //   type: 'column'
      // },
      chart: {
        type: 'area'
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        categories: categories
      },
      yAxis: {
        title: {
          text: null
        },
      },
      tooltip: {
        headerFormat: 'Stock: {point.key}<br/>',
        pointFormat: '{series.name}: {point.y}<br/>',
        shared: true
      },
      series: [
        { name: 'Initial Rate', data: initRate, color: '#8d8d8d'}, 
        { name: 'Market Rate', data: marketRate, color: '#2caffe'},
      ]
    }
  
    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={options}
      />
    )
  }

  export default Chart;