import React, { useEffect, useState } from 'react';
import { Layout, Table, Typography, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AuthCheck from '../User/AuthCheck';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const StocksByParticipant = () => {
  const [participants, setParticipants] = useState([]);
  const [data, setData] = useState([]);
  let params= useParams();

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findParticipant = participants.filter((e) => 
        e.userName.toLowerCase().indexOf(val) >= 0 
      )
      setData(findParticipant);
    } else {
      setData(participants);
    }
  };

  const columns = [
    {
      title: 'Participant',
      dataIndex: 'participant',
      key: 'participant',
      render: (_, record) => (<b>{record.participant}</b>)
    },
    {
      title: 'Buy',
      dataIndex: 'totalBuyQty',
      key: 'totalBuyQty'
    },
    {
      title: 'On Sell',
      dataIndex: 'totalOnSellQty',
      key: 'totalOnSellQty'
    },
    {
      title: 'Sold',
      dataIndex: 'totalSoldQty',
      key: 'totalSoldQty'
    },
    {
      title: (<>Qty<br/>Available</>),
      key: 'balance',
      render: (_, record) => (
        record.totalBuyQty - record.totalSoldQty
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/participants/view/${record.userId}`}><SearchOutlined /></Link>
        </Space>
      )
    }
  ]


  useEffect(()=> {
    let isMounted = true;
    const facthMarketList = () => {
    axios.get(`/api/market/stocks/${params.id}`,{ withCredentials: true }).then((res) => {
        if (isMounted) {
          const market = res.data.market;
          if(market) {
            setData(market);
            setParticipants(market);
          }
        }
      });
    }

    facthMarketList();

    return () => {
      isMounted = false;
    };
  }, [params])

  return (
    <AuthCheck>
      { participants && participants.length > 0 ? 
        <Content className="site-layout" style={{ padding: '24px 50px' }}>
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>{participants[0].stockName}</Title>
            <p>
              Discover a world of stocks in our simulator. Explore the list of available 
              stocks, each with its unique opportunities and potential. Dive into trading,
              build your portfolio, and strategize your way to success within this diverse
              stock market ecosystem.
            </p>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Space size="middle">
                <Search placeholder="search stock" onSearch={onSearch} style={{ width: 200 }} allowClear/>
              </Space>
            </div>
          </div>
          {
            data && data.length > 0 ? <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : <p>No data found!</p>
          }
        </Content> : <></>
      }
    </AuthCheck>
  );
}

export default StocksByParticipant;