import React from 'react';
import axios from 'axios';
import { Space, InputNumber, Button, Form, notification } from 'antd';


const ApplyForm = ({record, reloadData}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const onApply = (values) => {
    values.lotSize = record.lotSize;
    values.rate = record.rate;
    values.name = record.name;

    axios
      .post(
        `api/ipos/apply/${record._id}`,
        values,
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        reloadData();
        form.resetFields();
      }).catch((error) => {
        api.error({
          message: `Buy stock error`,
          description: error.response.data.error,
          placement: 'topRight'
        });
      });
  }

  const onCancel = () => {
    form.resetFields();
  }

  if(record.isApplied) return (<span style={{color: '#389e0d'}}>Applied</span>);

  return (
    <Form
      form={form}
      layout="vertical"
      name="Apply"
      onFinish={onApply}
    >
      {contextHolder}
      <Space align='start'>
        <Form.Item
          name="quantity"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Invalid quantity. Quantity should be between 1 to ${record.quantity}`,
              validator: (_, v) => {
                if ( v === null || v === undefined || v > record.quantity ) return Promise.reject();
                return Promise.resolve();
              }
            },
          ]}
        >
          <InputNumber min={1} controls={false} precision={0} placeholder='Quantity'/>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Apply
            </Button>
            <Button onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Space>
    </Form>
  );
}

export default ApplyForm;