import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Table, Typography, Space, notification, InputNumber, Button } from 'antd';
import { exportToExcel } from 'react-json-to-excel';
import { useParams, useHistory } from 'react-router-dom';
import AuthCheck from '../User/AuthCheck';

import axios from 'axios';

const { Content } = Layout;
const { Title } = Typography;

const ViewIPOs = () => {
  const [api, contextHolder] = notification.useNotification();
  const auth = useSelector((state) => state.auth);
  const [applications, setApplications] = useState(undefined);
  const [ipo, setIPO] = useState(undefined);

  let history = useHistory();
  let params= useParams();

  const columns = [
    {
      title: 'Participant Name',
      dataIndex: 'userName',
      key: 'userName',
      render: (_, record) => (<b>{record.userName}</b>),
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: 'Lot Asked',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Lot Size',
      dataIndex: 'lotSize',
      key: 'lotSize',
    },
    {
      title: 'Total Shares Ask',
      key: 'sharesAsk',
      render: (_, record) => (
        record.quantity * record.lotSize
      ),
      sorter: (a, b) => (a.quantity * a.lotSize) - (b.quantity * b.lotSize),
    },
    {
      title: 'Allocate Shares',
      key: 'allocate',
      render: (_, record) => (
        <Space size="middle">
          { 
            ipo.distributed ? 
              record.sharesAlloted :
              <InputNumber min={0} max={record.quantity * record.lotSize} defaultValue={record.sharesAlloted} onChange={(v) => {
                record.approved = v;
              }} />
          }
        </Space>
      )
    }
  ]

  useEffect(()=> {
    axios
      .get(
        `/api/ipos/applied/${params.id}`,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          const applied = response.data.applied;
          const ipo = response.data.ipo[0];
          if (!ipo.distributed) {
            const average = parseInt(ipo.lotSize / applied.length);
            applied.forEach((a) => {
              let allotment = a.quantity > average ? (average * a.lotSize) : (a.quantity * a.lotSize)
              a.sharesAlloted = allotment;
            })
          }
          setApplications(applied);
          setIPO(ipo);
        } else {
          api.error({
            message: `Get applied error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Add IPO error`,
          description: error.response.data.error,
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  }, [params, api, setApplications])

  const distribute = async () => {
    let totalShares = 0; 
    await applications.forEach( a => {
      if (!a.approved) a.approved = 0;
      totalShares += a.approved; 
    });
    if (totalShares <= 0)
      return api.error({
        message: `Distribution error`,
        description: `Total distribution should greater than 0.`,
        placement: 'topRight'
      });
    if (totalShares > (ipo.quantity * ipo.lotSize))
      return api.error({
        message: `Distribution error`,
        description: `Total distribution should not greater than number of available shares.`,
        placement: 'topRight'
      });
    
    axios
    .post(
      'api/ipos/distribute',
      {ipo , applications, totalShares},
      { withCredentials: true }
    )
    .then((response) => {
      if (!response.data.error) {
        console.log('IPO distributed successfully!');
        history.push('/ipos');
      } else {
        api.error({
          message: `IPO distribution error`,
          description: response.data.error,
          placement: 'topRight'
        });
        console.log(response.data.error);
      }
    }).catch((error) => {
      api.error({
        message: `Add IPO error`,
        description: error.response.data.error,
        placement: 'topRight'
      });
      console.log('login error: ', error);
    });
  }

  const exportExl = async () => {
    const exl = await applications.map(e => { return { Participant : e.userName, Ask: e.quantity * e.lotSize } });
    exportToExcel(exl, `ipo-${ipo.name}`);
  }

  return (
    <AuthCheck>
      {   
        auth?.isAdmin && setApplications && ipo ?
        <Content className="site-layout" style={{ padding: '24px 50px' }}>
          { contextHolder }
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>{ipo.name}</Title>
            <p>{ipo.description}</p>
          </div>
          {
            applications && applications.length > 0 ?
              <>
                <Space style={{ width: '100%', justifyContent: 'flex-end', paddingBottom: '12px' }}>
                  {`${ipo.quantity * ipo.lotSize} shares can distribute`}
                  { 
                    !ipo.distributed ?
                      <>
                        <Button type='primary' onClick={distribute}>
                          Ditribute Shares
                        </Button>
                        <Button onClick={exportExl}>
                          Export to Excel
                        </Button>
                      </> : <></>
                  }
                </Space>
                <Table columns={columns} dataSource={applications} rowKey="_id" pagination={false}/>
              </>: 
              <p>No data found!</p>
          }
        </Content> : <></>
      }
    </AuthCheck>
  );
}

export default ViewIPOs;