import React, {useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Form, Input, Row, Col, Typography, Button, Space, notification } from 'antd';
import axios from 'axios';
import { fetchNews } from '../../actions';
import AuthCheck from '../User/AuthCheck';
import ReactQuill from 'react-quill';

const { Title } = Typography;

const EditNews = () => {
  const [api, contextHolder] = notification.useNotification();
  const news = useSelector((state) => state.news);
  const dispatch = useDispatch();

  let history = useHistory();
  let params= useParams();

  const [form] = Form.useForm();

  useEffect(()=> {
    dispatch(fetchNews());
  }, [dispatch])

  useEffect(() => {
    if ( news && news.length > 0 ){
      const data = news.find(e => e._id === params.id);
      if (!data) return;
      form.setFieldsValue({
        title: data.title,
        description: data.description,
      })
    }
  }, [params, news, form]);

  const onSubmit = (values) => {
    axios
      .patch(
        `api/news/edit/${params.id}`,
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('News edited successfully!');
          history.push('/news');
        } else {
          api.error({
            message: `Edit news error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Edit news error`,
          description: 'Invalid data!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      { news ?
        <Layout style={{ padding: '24px 50px' }}>
          { contextHolder }
          <div style={{ paddingBottom: '12px' }}>
            <Title level={3}>Edit News</Title>
            <p>
              Contribute to the financial conversation! Add a news article to the simulator, 
              sharing relevant updates and insights with fellow participants. Shape the market 
              narrative and influence trading decisions with your informative posts.
            </p>
          </div>
          <Form
            form={form}
            layout="vertical"
            name="editnews"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please input title!',
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input description!',
                    }
                  ]}
                >
                  <ReactQuill theme="snow" style={{height: '200px', marginBottom: '42px'}}/>
                </Form.Item>
              </Col>
            </Row>
            <Space>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={()=> history.push('/news')}>
                  Cancel
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Layout> : <></>
      }
    </AuthCheck>
  );
}

export default EditNews;