import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Space, Table, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { fetchMarket } from '../../actions';
import AuthCheck from '../User/AuthCheck';
import BuyForm from './BuyForm';
import UpdateSellForm from './UpdateSellForm';
import AddSellForm from './AddSellForm';
import Chart from './Chart';

const { Content } = Layout;
const { Title } = Typography;

const ViewMarket = () => {
  const market = useSelector((state) => state.market);
  const events = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const [buy, setBuy] = useState([]);
  const [sell, setSell] = useState([]);
  const [stock, setStock] = useState();
  const [currentStock, setCurrentStock] = useState(0);
  const [onBuyQty, setOnBuyQty] = useState(0);
  const [onSellQty, setOnSellQty] = useState(0);
  const [avgBuyRate, setAvgBuyRate] = useState(0);
  const [transactions, setTransactions] = useState([]);
  
  let params= useParams();

  const buyColumns = [
    {
      title: 'Qauntity',
      key: 'quantity',
      render: (_, record) => record.quantity -  record.soldQty
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <BuyForm record={record}/>
      )
    }
  ]

  const sellColumns = [
    {
      title: 'Qauntity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Sold',
      key: 'soldQty',
      dataIndex: 'soldQty',
    },
    {
      title: 'Balance',
      key: 'balance',
      render: (_, record) => record.quantity -  record.soldQty
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <UpdateSellForm record={record}/>
      )
    }
  ]

  const average = arr => parseFloat(arr.reduce( ( p, c ) => p + c, 0 ) / arr.length).toFixed(0);

  useEffect(()=> {
    if(!market) return;

    const onBuy = [];
    let onBuyQty = 0;
    if (market.inMarket.length > 0) {
      market.inMarket.forEach( e => {
        e.sell.forEach( s => {
          if(s.quantity > s.soldQty){
            s.marketId = e._id;
            s.stockId = e.stockId;
            s.stockName = e.stockName;
            setOnBuyQty(onBuyQty += (s.quantity - s.soldQty));
            onBuy.push(s);
          }
        });
      });
    } else {
      setOnBuyQty(0);
    }
    setBuy(onBuy);

    const onSell = [];
    let sellQty = 0;
    if (market.ownStock[0])
      setCurrentStock(market.ownStock[0].totalBuyQty - market.ownStock[0].totalOnSellQty);
    if (market.ownStock.length > 0) {
      market.ownStock.forEach( e => {
        const buys = e.buy.map( s => s.rate );
        setAvgBuyRate(average(buys));
        e.sell.forEach( s => {
          if(s.quantity > s.soldQty){
            s.marketId = e._id;
            s.stockId = e.stockId;
            s.stockName = e.stockName;
            sellQty += (s.quantity - s.soldQty);
            onSell.push(s);
          }
        });
      });
    }
    setOnSellQty(sellQty);
    setSell(onSell);
    setStock(market.stock);

    const buyTransactions = [];
    market.allMarket.forEach( e => {
      e.buy.forEach( b => {
        buyTransactions.push({rate: b.rate, time: b.time});
      })
    });
    buyTransactions.sort((a,b) => new Date(a.time) - new Date(b.time));
    setTransactions(buyTransactions);
  }, [market])

  useEffect(()=> {
    dispatch(fetchMarket(params.id));
  }, [dispatch, params, events]);

  return (
    <AuthCheck>
      { 
        market ? 
          <Content className="site-layout" style={{ padding: '24px 50px' }}>
            <div style={{ paddingBottom: '12px' }}>
              <Space direction='horizontal'>
                <Title level={3}>{stock?.name} <small>({currentStock} shares in wallet)</small></Title>
                <span style={{color: '#f5222d'}}>Current market rate {stock?.marketRate}</span>
              </Space>
              <p>
                Discover a world of stocks in our simulator. Explore the list of available 
                stocks, each with its unique opportunities and potential. Dive into trading,
                build your portfolio, and strategize your way to success within this diverse
                stock market ecosystem.
              </p>
            </div>
            { 
              transactions.length > 0 ?
              <div style={{ paddingBottom: '42px' }}>
                <Chart transactions={transactions}/>
              </div> : <></>
            }
            <Title level={5}>Buy Shares <small>({onBuyQty} shares in market to buy)</small></Title>
            {
              buy && buy.length > 0 ? 
                <Table columns={buyColumns} dataSource={buy} rowKey="_id" pagination={false}/> : 
                <p>No shares to buy!</p>
            }
            <Space align='center' style={{ justifyContent: 'space-between', width: '100%', margin: "42px 0 8px 0", alignItems: 'flex-end'}}>
              <Title level={5} style={{ margin: 0 }}>
                Sell Shares
                <small> ({onSellQty} shares on sale, {currentStock} shares in wallet, average buy points {avgBuyRate} and current market rate is {stock?.marketRate}) </small>
              </Title>
              <AddSellForm name={market.stock.name} marketId={market.ownStock[0]?._id ? market.ownStock[0]._id : undefined } currentStock={currentStock}/>
            </Space>
            {
              sell && sell.length > 0 ? 
                <Table columns={sellColumns} dataSource={sell} rowKey="_id" pagination={false}/> :
                <p>No shares add for sale!</p>
            }
          </Content>
        : <></>
      }
    </AuthCheck>
  );
}

export default ViewMarket;