import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchMarket } from '../../actions';
import { useParams } from 'react-router-dom';
import { Space, InputNumber, Button, Form, notification } from 'antd';


const UpdateSellForm = ({record}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let params= useParams();

  useEffect(() => {
    form.setFieldsValue({
      quantity: record.quantity -  record.soldQty,
      rate: record.rate
    })
  }, [form, record]);

  const update = (values) => {
    axios
      .patch(
        'api/market/sell/update',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        api.success({
          message: `Sell edit`,
          description: response.data?.message ? response.data.message : 'Sell edited and listed in the market successfully!',
          placement: 'topRight'
        });
        dispatch(fetchMarket(params.id));
        form.resetFields();
      }).catch((error) => {
        api.error({
          message: `Sell errors`,
          description: error.response?.data ? error.response.data : 'Something went worng. Contact administrator.',
          placement: 'topRight'
        });
      });
  }

  const onUpdateSell = (values) => {
    values.sellId = record._id;
    values.marketId = record.marketId;
    update(values);
  }

  const onDeleteSell = () => {
    update({
      marketId: record.marketId,
      sellId: record._id,
      quantity: record.quantity - record.soldQty,
      rate: record.rate,
      isDelete: true
    })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="Buy"
      onFinish={onUpdateSell}
    >
      {contextHolder}
      <Space align='start'>
        <Form.Item
          name="quantity"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: 'Invalid quantity.',
              validator: (_, v) => {
                if ( v === null || v === undefined || v > (record.quantity -  record.soldQty) )
                  return Promise.reject('Invalid quantity.');
                return Promise.resolve();
              }
            },
          ]}
        >
          <InputNumber min={1} controls={false} precision={0}/>
        </Form.Item>
        <Form.Item
          name="rate"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: 'Invalid rate. Rate amount should greater than 0.',
            },
          ]}
        >
          <InputNumber min={1} controls={false} precision={0}/>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
            <Button onClick={onDeleteSell}>
              Delete
            </Button>
          </Space>
        </Form.Item>
      </Space>
    </Form>
  );
}

export default UpdateSellForm;