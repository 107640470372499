import React from 'react';
import { Layout, Form, Input, Button, Typography, notification } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';

const { Title } = Typography;

const Login = (props) => {
  const [api, contextHolder] = notification.useNotification();

  const onSubmit = (values) => {
    axios
      .post(
        '/auth/login',
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log('login response', response);

        if (response.status === 200) {
          console.log(`You logged in, ${response.data.email}`);
          window.location.replace('/');
        }
      })
      .catch((error) => {
        api.error({
          message: `Login error`,
          description: 'Invalid email address or password!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const renderContent = () => {
    console.log('is blank: ', props.auth === '');
    switch (props.auth) {
      case null:
        return;
      case '':
        return (
          <Layout
            style={{ minHeight: '100vh', justifyContent: 'center', maxWidth: '540px', padding: '0 50px', margin: 'auto' }}
          >
            <div style={{ textAlign: 'center', paddingBottom: '4%' }}>
              <Title>Log In</Title>
              <a className='logo' href="/" style={{ margin: 0 }}>Q<span style={{color: '#595959'}}>ShareMarket</span></a>
              <p>
                Dive into the exciting world of stock trading with our Stock Market Simulator game application. 
                Learn, strategize, and compete in a risk-free environment.
              </p>
            </div>
            <Form
              name="login"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onSubmit}
              onFinishFailed={onSubmitFailed}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item style={{ textAlign: 'center'}}>
                <Button type="primary" htmlType="submit" style={{ width: '200px'}}>
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </Layout>
        );
      default:
        return <Redirect to="/" />;
    }
  };

  return <>
    {contextHolder}
    {renderContent()}
  </>;

}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, actions)(Login);
