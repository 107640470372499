import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Typography, InputNumber, Button, Space, notification } from 'antd';
import axios from 'axios';
import AuthCheck from '../User/AuthCheck';

const { Title } = Typography;
const { TextArea } = Input;

const AddIPO = () => {
  const [api, contextHolder] = notification.useNotification();
  let history = useHistory();

  const onSubmit = (values) => {
    axios
      .post(
        'api/ipos/add',
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('IPO added successfully!');
          history.push('/ipos');
        } else {
          api.error({
            message: `Add IPO error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Add IPO error`,
          description: error.response.data.error,
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      <Layout style={{ padding: '24px 50px' }}>
        { contextHolder }
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Add IPO</Title>
          <p>
            Fuel the excitement! Introduce a new IPO to the simulator by providing its name, 
            specifying the quantity available, and setting the IPO value in points per lot. 
            Offer participants the chance to invest in promising ventures and keep the market 
            buzzing with fresh opportunities.
          </p>
        </div>
        <Form
          layout="vertical"
          name="addIPO"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input ipo name!',
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Lot Size"
                name="lotSize"
                rules={[
                  {
                    required: true,
                    message: 'Invalid lot size. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject();
                    }
                  },
                ]}
              >
                <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: 'Invalid quantity. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject();
                    }
                  },
                ]}
              >
                <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Rate"
                name="rate"
                rules={[
                  {
                    required: true,
                    message: 'Invalid rate. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject('Invalid rate. It should more than 0!');
                    }
                  },
                ]}
              >
                <InputNumber min={1} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Description"
                name="description"
              >
                  <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={()=> history.push('/stocks')}>
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Layout>
    </AuthCheck>
  );
}

export default AddIPO;