import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Typography, Space, Input, Button } from 'antd';
import { EditOutlined, SearchOutlined, VerticalAlignMiddleOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { fetchStocks } from '../../actions';
import AuthCheck from '../User/AuthCheck';
import axios from 'axios';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const Stocks = () => {
  const stocks = useSelector((state) => state.stocks);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let history = useHistory();

  const [data, setData] = useState([]);

  const onSearch = (value, _e) => {
    const val = value.trim().toLowerCase();
    if(val.length > 0) {
      const findStocks = stocks.filter((e) => 
        e.name.toLowerCase().indexOf(val) >= 0 ||
        e.description.toLowerCase().indexOf(val) >= 0
      )
      setData(findStocks);
    } else {
      setData(stocks);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<b>{record.name}</b>),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Initial Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'On Sell',
      dataIndex: 'onSell',
      key: 'onSell',
      sorter: (a, b) => a.onSell - b.onSell,
    },
    {
      title: 'Rate',
      key: 'rate',
      render: (_, record) => (
        <Space size="middle">
          <span style={{textAlign: 'right', display: 'inline-block', lineHeight: 1}}>
            {record.marketRate}<br/>
            <small style={{color: '#adadad'}}>{record.rate}</small>
          </span>
          { record.marketRate === record.rate ? <VerticalAlignMiddleOutlined style={{color: '#fa8c16'}}/> : <></> }
          { record.marketRate > record.rate ? <ArrowUpOutlined style={{color: '#389e0d'}}/> : <></> }
          { record.marketRate < record.rate ? <ArrowDownOutlined style={{color: '#f5222d'}}/> : <></> }
        </Space>
      ),
      sorter: (a, b) => a.marketRate - b.marketRate,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          { auth?.isAdmin ? <Link to={`/stocks/edit/${record._id}`}><EditOutlined /></Link> : <></> }
          <Link to={`/stocks/view/${record._id}`}><SearchOutlined /></Link>
          { auth?.isAdmin ? <Link to={`/stocks/participants/${record._id}`}><ExclamationCircleOutlined /></Link> : <></> }
        </Space>
      )
    }
  ]

  useEffect(()=> {
    dispatch(fetchStocks());
  }, [dispatch])

  useEffect(()=> {
    if(stocks === null || stocks?.length === 0) return;

    let isMounted = true;
    const facthMarketList = () => {
      axios.get('/api/market/list',{ withCredentials: true }).then((res) => {
        if (isMounted) {
          const market = res.data;
          const _stocks = JSON.parse(JSON.stringify(stocks));
          if(market && _stocks) {
            _stocks.forEach(s => {
              let onSell = 0;
              market.forEach( m => {
                if (m.stockId === s._id) {
                  onSell += (m.totalOnSellQty - m.totalSoldQty);
                }
              });
              s.onSell = onSell;
            });
            setData(_stocks);
          }
        }
      });
    }

    facthMarketList();

    return () => {
      isMounted = false;
    };
  }, [stocks])

  return (
    <AuthCheck>
      <Content className="site-layout" style={{ padding: '24px 50px' }}>
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Study Circle Index</Title>
          <p>
            Discover a world of stocks in our simulator. Explore the list of available 
            stocks, each with its unique opportunities and potential. Dive into trading,
            build your portfolio, and strategize your way to success within this diverse
            stock market ecosystem.
          </p>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Space size="middle">
              <Search placeholder="search stock" onSearch={onSearch} style={{ width: 200 }} allowClear/>
              { auth?.isAdmin ? <Button type='primary' onClick={() => history.push('/stocks/add')}>Add Stock</Button> : <></> }
            </Space>
          </div>
        </div>
        {
          data && data.length > 0 ? <Table columns={columns} dataSource={data} rowKey="_id" pagination={false}/> : <p>No data found!</p>
        }
      </Content>
    </AuthCheck>
  );
}

export default Stocks;