import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Form, Row, Col, Typography, InputNumber, Button, Space, notification, Switch } from 'antd';
import axios from 'axios';
import AuthCheck from '../User/AuthCheck';

const { Title } = Typography;

const Settings = () => {
  const [api, contextHolder] = notification.useNotification();
  const auth = useSelector((state) => state.auth);
  const [id, setId] = useState(undefined);
  let history = useHistory();
  const [form] = Form.useForm();

  if (!auth?.isAdmin){
    history.push(`/`);
  }

  useEffect(()=> {
    let isMounted = true;
    const facthMarketList = () => {
      axios.get('/api/settings/list',{ withCredentials: true }).then((res) => {
        if (isMounted) {
          const s = res.data[0];
          if (!s) return;
          setId(s._id)
          form.setFieldsValue({
            maxTradePercentage: s.maxTradePercentage,
            maxCircuitPercentage: s.maxCircuitPercentage,
            lowerCircuitPercentage: s.lowerCircuitPercentage,
            locked: s.locked
          });
        }
      });
    }

    facthMarketList();

    return () => {
      isMounted = false;
    };
  }, [form, setId])

  const onSubmit = (values) => {
    axios
      .patch(
        `/api/settings/edit/${id}`,
        values,
        { withCredentials: true }
      )
      .then((response) => {
        if (!response.data.error) {
          console.log('Settings updated successfully!');
          history.push(`/settings`);
        } else {
          api.error({
            message: `Setting error`,
            description: response.data.error,
            placement: 'topRight'
          });
          console.log(response.data.error);
        }
      }).catch((error) => {
        api.error({
          message: `Setting error`,
          description: 'Invalid data!',
          placement: 'topRight'
        });
        console.log('login error: ', error);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthCheck>
      <Layout style={{ padding: '24px 50px' }}>
        { contextHolder }
        <div style={{ paddingBottom: '12px' }}>
          <Title level={3}>Settings</Title>
        </div>
        <Form
          form={form}
          layout="vertical"
          name="settings"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Max Trade Percentage"
                name="maxTradePercentage"
                rules={[
                  {
                    required: true,
                    message: 'Invalid quantity. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject('Invalid quantity. It should more than 0!');
                    }
                  }
                ]}
              >
                <InputNumber min={1} max={100} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Max Circuit Percentage"
                name="maxCircuitPercentage"
                rules={[
                  {
                    required: true,
                    message: 'Invalid quantity. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject('Invalid quantity. It should more than 0!');
                    }
                  },
                ]}
              >
                <InputNumber min={1} max={100} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Lower Circuit Percentage"
                name="lowerCircuitPercentage"
                rules={[
                  {
                    required: true,
                    message: 'Invalid quantity. It should more than 0!',
                    validator: (_, v) => {
                      return v > 0 ? Promise.resolve() : Promise.reject('Invalid quantity. It should more than 0!');
                    }
                  },
                ]}
              >
                <InputNumber min={1} max={100} controls={false} precision={0} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Is Locked"
                name="locked"
                valuePropName="checked"
                rules={[{required: true}]}
              >
                <Switch/>
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Layout>
    </AuthCheck>
  );
}

export default Settings;