import { combineReducers } from 'redux';
import authReducer from './authReducer';
import participantsReducer from './participantsReducer';
import stocksReducer from './stocksReducer';
import marketReducer from './marketReducer';
import portfolioReducer from './portfolioReducer';
import iposReducer from './iposReducer';
import ipoApplicationsReducer from './ipoApplicationsReducer';
import newsReducer from './newsReducer';
import eventReducer from './eventReducer';

export default combineReducers({
  auth: authReducer,
  participants: participantsReducer,
  stocks: stocksReducer,
  market: marketReducer,
  portfolio: portfolioReducer,
  ipos: iposReducer,
  ipoApplications: ipoApplicationsReducer,
  news: newsReducer,
  events: eventReducer,
});
