import { SELL_UPDATED } from '../actions/types';

const initialState = {
  sellUpdate: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SELL_UPDATED:
      return {
        ...state,
        sellUpdate: state.sellUpdate++
      }
    default:
      return state;
  }
}
